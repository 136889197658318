import { defineNuxtPlugin } from '#app/nuxt'
import { LazyBlueButton, LazyDivider, LazyIncentivesFourColumnIcons, LazyFaq, LazyStacked, LazyFeature, LazyFullImage, LazyFullRichText, LazyBlankSpace, LazyBlueWorkerBanner, LazyRichText, LazyStepsGrid, LazyGrid, LazyGuidesGrid, LazyHero, LazyRoundedImageText, LazyTextRoundedImage, LazyGridList, LazyListItem, LazyLocationPage, LazyLocationsGrid, LazyPage, LazyOurStory, LazyPartnersGrid, LazyCards, LazyBookCTA, LazyHeaderSectionCentered, LazyTextImageLeft, LazyTextImageRight, LazyThreeUsps, LazyVerticalUsps, LazyServicesGrid, LazyServicesInCategories, LazySkipHireNearMeSizes, LazyWasteIcon, LazyWasteTypes, LazyMicroReview, LazyTestimonials, LazyCircleGradient, LazyPurpleGradient } from '#components'
const lazyGlobalComponents = [
  ["BlueButton", LazyBlueButton],
["Divider", LazyDivider],
["IncentivesFourColumnIcons", LazyIncentivesFourColumnIcons],
["Faq", LazyFaq],
["Stacked", LazyStacked],
["Feature", LazyFeature],
["FullImage", LazyFullImage],
["FullRichText", LazyFullRichText],
["BlankSpace", LazyBlankSpace],
["BlueWorkerBanner", LazyBlueWorkerBanner],
["RichText", LazyRichText],
["StepsGrid", LazyStepsGrid],
["Grid", LazyGrid],
["GuidesGrid", LazyGuidesGrid],
["Hero", LazyHero],
["RoundedImageText", LazyRoundedImageText],
["TextRoundedImage", LazyTextRoundedImage],
["GridList", LazyGridList],
["ListItem", LazyListItem],
["LocationPage", LazyLocationPage],
["LocationsGrid", LazyLocationsGrid],
["Page", LazyPage],
["OurStory", LazyOurStory],
["PartnersGrid", LazyPartnersGrid],
["Cards", LazyCards],
["BookCTA", LazyBookCTA],
["HeaderSectionCentered", LazyHeaderSectionCentered],
["TextImageLeft", LazyTextImageLeft],
["TextImageRight", LazyTextImageRight],
["ThreeUsps", LazyThreeUsps],
["VerticalUsps", LazyVerticalUsps],
["ServicesGrid", LazyServicesGrid],
["ServicesInCategories", LazyServicesInCategories],
["SkipHireNearMeSizes", LazySkipHireNearMeSizes],
["WasteIcon", LazyWasteIcon],
["WasteTypes", LazyWasteTypes],
["MicroReview", LazyMicroReview],
["Testimonials", LazyTestimonials],
["CircleGradient", LazyCircleGradient],
["PurpleGradient", LazyPurpleGradient],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
